/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useIntl } from 'react-intl'

import { useEffect } from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import Footer from '../../components/Footer'
import { fetchSurvey, getSurveyDetails, getLanguage } from '@mzaednica/core'
import Header from '../../components/Header'
import findImageWithQuality from '../../utils/findImageWithQuality'
import { LANGUAGES } from '../../i18n/settings/languages'
import ReactMarkdown from 'react-markdown'
import { isEmpty } from 'lodash'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import defaultImage from '../../img/app_icon.png'
import { constructAbsoluteAppUrl } from '../../utils/constructAbsoluteAppUrl'

const SurveyDetails = ({ fetchSurvey, surveyDetails, language }) => {
  let { id } = useParams()
  const history = useHistory()
  const { surveyData } = surveyDetails

  useEffect(() => {
    if (id) {
      fetchSurvey(id)
    }
  }, [fetchSurvey, id])

  useEffect(() => {
    if (id) {
      fetchSurvey(id)
    }
  }, [fetchSurvey, id])
  const { formatMessage } = useIntl()

  return (
    <>
      <Header />
      {!isEmpty(surveyData) && (
        <div className="container mt-5 mb-20 max-w-3xl flex flex-col justify-center">
          {surveyData.image ? (
            <div className="relative w-full h-64 overflow-hidden flex justify-center items-center mb-10">
              <img
                className="absolute inset-0 object-cover h-full w-full"
                src={constructAbsoluteAppUrl(
                  findImageWithQuality(surveyData.image, 'medium'),
                )}
                alt=""
              />
            </div>
          ) : (
            <div className="relative w-full h-64 overflow-hidden flex justify-center items-center mb-10">
              <img
                className="absolute inset-0 object-cover h-full w-full"
                src={defaultImage}
                alt=""
              />
            </div>
          )}
          <div className="uppercase text-lg mb-8 font-semibold">
            {formatMessage({ id: 'survey' })}
            {language === LANGUAGES.MK
              ? surveyData.title
              : surveyData.title_sq ?? surveyData.title}
          </div>
          <div>
            <div className="text-lg font-semibold">
              {formatMessage({ id: 'surveyDescription' })}
            </div>
            <ReactMarkdown>
              {language === LANGUAGES.MK
                ? surveyData.description
                : surveyData.description_sq ?? surveyData.description}
            </ReactMarkdown>
          </div>
          <div className="flex justify-center">
            {!surveyData.answered &&
              surveyData.active &&
              surveyData?.published_at && (
                <button
                  className="sticky top-0 bg-nav bg-left-top bg-no-repeat bg-cover z-50 p-2 text-md font-semibold text-white rounded-xl max-w-xs mt-8 self-center"
                  onClick={() => history.push(`/SurveyAnswerPage/${id}`)}
                >
                  {formatMessage({ id: 'participate' })}
                </button>
              )}

            {surveyData.answered && surveyData?.published_at && (
              <div className="flex space-x-4">
                <button
                  className="sticky top-0 bg-nav bg-left-top bg-no-repeat bg-cover z-50 p-2 text-md font-semibold text-white rounded-xl max-w-xs mt-8 self-center"
                  onClick={() => history.push(`/SurveyAnswerPage/${id}`)}
                >
                  {formatMessage({ id: 'changeAnswers' })}
                </button>
                <button
                  className="sticky top-0 bg-nav bg-left-top bg-no-repeat bg-cover z-50 p-2 text-md font-semibold text-white rounded-xl max-w-xs mt-8 self-center"
                  onClick={() => history.push(`/surveysStatistics/${id}`)}
                >
                  {formatMessage({ id: 'seeStatistics' })}
                </button>
              </div>
            )}

            {!surveyData.active && surveyData?.published_at && (
              <button
                className="sticky top-0 bg-nav bg-left-top bg-no-repeat bg-cover z-50 p-2 text-md font-semibold text-white rounded-xl max-w-xs mt-8 self-center"
                onClick={() => history.push(`/surveysStatistics/${id}`)}
              >
                {formatMessage({ id: 'seeStatistics' })}
              </button>
            )}
          </div>
        </div>
      )}

      <Footer />
    </>
  )
}

const mapStateToProps = (state) => ({
  surveyDetails: getSurveyDetails(state),
  language: getLanguage(state),
})

const mapDispatchToProps = {
  fetchSurvey,
}

export default connect(mapStateToProps, mapDispatchToProps)(SurveyDetails)
