import * as yup from 'yup'

export const CompleteProfileSchema = yup.object().shape({
  municipality: yup.number().required('requiredInput'),
  accountType: yup.string().required('requiredInput'),
  birth: yup.date().required('requiredInput'),
  firstName: yup.string().trim().required('requiredInput'),
  familyName: yup.string().trim().required('requiredInput'),
  gender: yup.string().required('requiredInput'),
  interests: yup.array().notRequired(),
})
